<template>
<div>
    <div class="basic" >
        <h3>校园认证</h3>
    </div>
    <div class="schToken">

        <!-- <form action="" class="form_schToken"> -->
        <el-form :model="form" status-icon ref="ruleForm" label-width="100px" v-if="tokenSuccess"  class="demo-ruleForm">
        <div class="sch">
          <el-form-item >
            <label>可以用学号和手机号找回账号，建议认证</label>
          </el-form-item>
             <el-form-item >
            <label>您的学校</label>
            <el-input type="text" name="sch" v-model="form.school" @input="isPointer" placeholder="学校"></el-input>
            </el-form-item>
        </div>
        <div class="sch">
            <el-form-item >
            <label>您的学号</label>
            <el-input type="text" name="stu_id"  @input="isPointer" v-model="form.stuId" placeholder="学号"></el-input>

            </el-form-item>
        </div>
        <div class="submit">
        <el-button class="el-button--primary" type="button" :disabled=isEdit  @click.prevent.stop="sub">提交</el-button>
        <el-button style="padding:12px 20px; margin-left:30px" type="button" class="el-button--default" @click="resetForm">重置</el-button>
        </div>
         <!-- 成功认证 -->

</el-form>

<el-empty description="已通过认证" v-else class="success">
  <el-button type="primary" @click="changeSchToken">修改信息</el-button>
</el-empty>
    </div>

</div>
</template>

<script>
import { schToken } from '../../API/User.js'
import { mapActions } from 'vuex'
export default {
  name: 'schToken',
  data () {
    return {
      form: {
        school: '',
        stuId: ''
      },
      schToken: this.$store.state.userInfo.schToken,
      tokenSuccess: true,
      isEdit: true
    }
  },
  methods: {
    ...mapActions(['initUserInfo']),
    isPointer () {
      if (this.form.school === '' || this.form.stuId === '') {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
    },
    // 校园认证
    async sub () {
      const { data: res } = await schToken(this.form)
      //   重新获取用户信息
      this.initUserInfo()
      if (res.status === 0) {
        this.$message({
          showClose: true,
          message: '认证成功',
          type: 'success'
        })
      }
      setTimeout(() => {
        this.$router.replace({
          path: '/user/userInfo'
        })
      }, 1500)
    },
    isSchToken () {
      // console.log(this.schToken)
      if (this.schToken === 0) {
        this.tokenSuccess = false
      } else {
        this.tokenSuccess = true
      }
    },
    resetForm (formName) {
      this.form.school = ''
      this.form.stuId = ''
    },
    // 修改校园认证信息
    changeSchToken () {
      this.$router.push({
        path: '/user/userSet'
      })
    }
  },
  created () {
    this.isSchToken()
  }
//   computed: {
//     userInfo: function () {
//       return this.$store.state.userInfo
//     }
//   }
}
</script>

<style lang = "less" scoped>

.basic{
        background-color: #fff;
        h3{
            text-align: left;
            height: .8rem;
            line-height: .8rem;
            font-size: .3rem;
            font-weight: 600;
            color: #2e2e2e;
            padding-left: .2667rem;
            margin:  0;
            border-bottom: 1px solid #f0f0f2
        }
}
.schToken{
    padding: 0 0 .25rem;
    background-color: #fff;
    .el-form{
        width: 60%;
        margin: 0 auto;
    }
    .form_schToken{
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .sch{
            margin: .1667rem 0;
            label{
                width: 1.6667rem;
                text-align: right;
                font-size: .2333rem;
                color: #4d4d4d;
            }
            input{
                width: 4.2rem;
                height: .5333rem;
                line-height: .5rem;
                margin-left: .1333rem;
                border: 1px solid #ccc;
                border-radius: .0667rem;
                padding: 0 .1333rem;
                font-size: .2333rem;
                color: #4d4d4d;
            }

        }

    }
    .submit{
        margin: .3rem 0 0 ;
        display: flex;
        justify-content: center;
        button{
             padding: 12px 20px;
        }
        .el-form{
        margin-left: .6667rem;
        }
    }
}

</style>
